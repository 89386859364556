<template>
  <!-- 案例展示 -->
  <div class="Video">
    <Banner v-bind="bannerProps" />

    <!-- 正文 -->
    <div class="VideoBox">
      <!-- 分类按钮 -->
      <div class="classBtnBox">
        <div class="left">
          视频分类
        </div>
        <div class="a">
          <span
            class="right"
            :class="{ active: mainindex == null }"
            @click="all"
          >全部</span>
          <template v-for="(item, index) in videoClassList">
            <div
              :key="item.id"
              class="b"
              :class="{ active: index == mainindex }"
              @click="onMain(item.id, index)"
            >
              <span class="right">
                {{ item.videoClassName }}
              </span>
            </div>
          </template>
        </div>
      </div>

      <!-- 案例 -->
      <ElRow id="routerhash">
        <ElCol v-for="item in videoList" :key="item.id" :span="6">
          <RouterLink :to="{ path: '/videoContent', query: { id: item.id } }">
            <ElCard :body-style="{ padding: '0px' }">
              <div class="img">
                <img :src="item.cover" class="image">
              </div>
              <div class="wenzi">
                <span>{{ item.title }}</span>
              </div>
            </ElCard>
          </RouterLink>
        </ElCol>
      </ElRow>

      <!-- 分页按钮 -->
      <PaginationButton
        v-show="total > limit"
        :total="total"
        :page="pages"
        :limit="limit"
        @currentChange="currentChange"
      />
    </div>
  </div>
</template>

<script>
import { videoClass, video } from '../api/video';
import Banner from '@/components/banner.vue';
import PaginationButton from '@/components/Pagination.vue';

export default {
  name: 'VideoIndex',
  components: {
    Banner,
    PaginationButton,
  },
  data() {
    return {
      bannerProps: {
        backgroundImage: 'https://img.kcgeis.com/4156568117.jpg',
        cnTitle: '视频中心',
        cnTitleColor: '#018835',
        enTitle: 'VIDEO',
        enTitleColor: '#fff',
      },
      mainindex: null,
      videoClassList: [],
      videoList: [],
      total: 0,
      pages: 1,
      limit: 16,
      classId: '',
    };
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      async handler(newV) {
        const { index } = newV;
        await this.getClass();
        this.$nextTick(() => {
          const { id } = newV;
          this.onMain(id, index);
        });
      },
    },
  },

  methods: {
    currentChange(e) {
      this.pages = e;
      this.getData();
    },
    all() {
      this.mainindex = null;
      this.classId = '';
      this.getData();
    },
    onMain(id, index) {
      this.mainindex = index;
      this.classId = id;
      this.getData();
    },
    async getClass() {
      try {
        const classData = await videoClass();
        this.videoClassList = classData.data;
      } catch (error) {
        this.$message.error(error);
      }
    },
    async getData() {
      try {
        const params = {
          videoClassId: this.classId,
          limit: this.limit,
          pages: this.pages,
        };
        const infoData = await video(params);
        this.videoList = infoData.data.records;
        this.total = infoData.data.total;
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  height: 100%;
  border-radius: 5px;
  background-color: #018835;
  color: #fff;
}
.Video {
  width: 100%;
  min-height: 1000px;
  .bannerBox {
    position: relative;
    img {
      width: 100%;
    }
    .txt {
      position: absolute;
      top: 100px;
      right: 140px;
      h2 {
        font-size: 52px;
        color: #fff;
        font-weight: bold;
        text-align: right;
      }
      h3 {
        font-size: 52px;
        font-weight: bold;
        color: #018835;
        text-align: right;
      }
    }
  }
  .VideoBox {
    width: 1200px;
    margin: 40px auto 0;
    .classBtnBox {
      // width: 100%;
      border: 1px #e5e5e5 solid;

      // background-color: pink;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .left {
        height: 45px;
        width: 137px;
        background: #f9f9f9;
        font-size: 12px;
        color: #666;
        border-right: #e5e5e5 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .a {
        width: 100%;
        display: flex;
        margin-left: 10px;
        .b {
          margin: 0 10px;
        }
        .right {
          height: 100%;

          padding: 5px;
          font-size: 12px;
          // color: #666;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.5s;
          &:hover {
            cursor: pointer;
            border-radius: 5px;
            background-color: #018835;
            color: #fff;
            transition: 0.5s;
          }
        }
      }
    }
  }
  .el-row {
    margin-top: 30px;
    .el-card {
      margin: 0 10px 60px;
      .img {
        height: 150px;
        overflow: hidden;
      }
      .image {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      &:hover .wenzi {
        background-color: #018835;
        color: #fff;
      }
    }
    .wenzi {
      height: 70px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
    }
  }
  .pagination {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #018835;
    color: #fff;
  }
}
</style>
